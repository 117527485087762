@import "@/assets/scss/var.scss";
.returnApply {
    background: #f7f8fa;
    overflow: auto;
    &-title {
        font-size: $fontsize-lg;
        margin: $offset-v 0;
    }
    &-content {
        background: #fff;
        padding: 20px;
    }
    &-goods {
        margin: 20px 0;
    }
    .amt {
        color: $color-primary;
        font-weight: bold;
        font-size: 16px;
    }
    .el-select {
        width: 100%;
    }
    .el-cascader {
        width: 100%;
    }
    .part{
        &-price{
            >div{
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                >span:last-child {
                    width: 150px;
                    display: inline-block;
                    text-align: right;
                }
            }
            margin-bottom: 20px;
            border: 1px solid #DCDFE6;
            border-top: none;
            
            
        }
        &-tips{
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 0; left: 15px; right: 15px;
                border-top: 1px dashed #DCDFE6;
            }
            >i{
                padding-left: 5px;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
    .cpnSetPop{
        /deep/ .el-dialog{
            .el-dialog__body{
                padding-top: 10px;
            }
        }
        &-item,.Coupon-item{
            margin-bottom: 10px;
        }
        &-title{
            margin-bottom: 10px;
        }
    }
    &.returnPart {
        .returnApply-goods {
            margin-bottom: 0;
            padding: 15px 25px;
            border: 1px solid #DCDFE6;
            border-bottom: none;
        }
    }
}
